<template>
    <section class="hero" id="sectionHero">
        <h1 class="title animated fadeInDown delay-1s" id="helloTitle">
            Hello there, I'm Matt!
        </h1>
        <h2 class="subtitle animated fadeIn delay-2s" id="introSubtitle">
            I'm a software engineer.
        </h2>
        <a href="#AboutSection" id="arrowHREF"> <!-- TODO: give this <a> an id, use it for margin-top instead of <i> element -->
            <span class="icon" id="arrowIcon">
                <i class="fas fa-angle-down animated fadeInUp delay-3s"></i>
            </span>
        </a>
    </section>
</template>

<script>



export default {
    name: 'HomeHero',
    methods: {
        handleResize: function() {
            var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
            var viewportHeight = window.innerHeight || document.documentElement.clientHeight;

            if (viewportWidth <= 500 && viewportHeight <= 1400) {
                document.getElementById('helloTitle').classList.remove('is-1');
                document.getElementById('introSubtitle').classList.remove('is-2');
                document.getElementById('arrowIcon').classList.remove('fa-4x');

                document.getElementById('helloTitle').classList.add('is-3');
                document.getElementById('introSubtitle').classList.add('is-4');
                document.getElementById('arrowIcon').classList.add('fa-2x');
            } else {
                document.getElementById('helloTitle').classList.remove('is-3');
                document.getElementById('introSubtitle').classList.remove('is-4');
                document.getElementById('arrowIcon').classList.remove('fa-2x');

                document.getElementById('helloTitle').classList.add('is-1');
                document.getElementById('introSubtitle').classList.add('is-2');
                document.getElementById('arrowIcon').classList.add('fa-4x');
            }
        },

    },
    mounted() {
        document.getElementById('sectionHero').classList.add('hero-large');
        window.addEventListener('resize', this.handleResize);

        if (navigator.userAgent.includes('Instagram')) {
            document.getElementById('helloTitle').classList.add('is-3');
            document.getElementById('introSubtitle').classList.add('is-4');
            document.getElementById('arrowIcon').classList.add('fa-2x');
            return;
        }

        var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        var viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        if (viewportWidth <= 500 && viewportHeight <= 1400) {
            document.getElementById('helloTitle').classList.add('is-3');
            document.getElementById('introSubtitle').classList.add('is-4');
            document.getElementById('arrowIcon').classList.add('fa-2x');
        } else {
            document.getElementById('helloTitle').classList.add('is-1');
            document.getElementById('introSubtitle').classList.add('is-2');
            document.getElementById('arrowIcon').classList.add('fa-4x');
        }
    }
};
</script>

<style scoped>
/*
  image1: rtCujH697DU
  image2: ddLiNMqWAOM
  image3: KidY3t8O4PE
*/
a {
    color: darkgrey;
}

.title,
.subtitle {
    color: darkgrey;
}

.hero-large {
    height: 100vh;
    background: url('../assets/mountain.jpeg');
    background-size: cover;
    background-position: center;
}
.hero-small {
    height: 100vh;
    background: url('../assets/mountaincropped.jpeg');
    background-size: cover;
}


#helloTitle {
    position: absolute;
    top: 50px;
    right: 0;
    left: 0;
}

#introSubtitle {
    position: absolute;
    top: 130px;
    right: 0;
    left: 0;
}

#arrowHREF {
    position: absolute;
    bottom: 50px;
    right: 0;
    left: 0;
    width: 5%;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 500px) and (max-height: 1400px) {
    #helloTitle {
        position: absolute;
        top: 50px;
        right: 0;
        left: 0;
    }

    #introSubtitle {
        position: absolute;
        top: 105px;
        right: 0;
        left: 0;
    }

    #arrowHREF {
        position: absolute;
        bottom: 50px;
        right: 0;
        left: 0;
    }
}

@media only screen and (max-width: 1400px) and (max-height: 500px) {
    #helloTitle {
        position: absolute;
        top: 25px;
        right: 0;
        left: 0;
    }

    #introSubtitle {
        position: absolute;
        top: 95px;
        right: 0;
        left: 0;
    }

    #arrowHREF {
        position: absolute;
        bottom: 50px;
        right: 0;
        left: 0;
    }
}

@media only screen and (max-height: 265px) {
    #helloTitle {
        position: absolute;
        top: 25px;
        right: 0;
        left: 0;
    }

    #introSubtitle {
        position: absolute;
        top: 85px;
        right: 0;
        left: 0;
    }

    #arrowHREF {
        position: sticky;
        margin-top: 8%;
    }
    .hero-large {
        height: 100vh;
        background: url('../assets/mountain.jpeg');
        background-size:100%;
        background-position: center;
        padding: 20%;

    }
}
</style>
